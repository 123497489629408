import { CalendarState } from '../../components/BookingCalendar/controller';
import { getTodayLocalDateTimeStartOfDay } from '../dateAndTime/dateAndTime';
import { getLocalDateTimeRangeForDay } from '../getLocalDateTimeRangeForDay/getLocalDateTimeRangeForDay';
import { CalendarContext } from '../context/contextFactory';
import { SlotsStatus } from '../../types/types';

const clearCalendarErrors = (state: CalendarState) => {
  const { initialErrors, calendarErrors } = state;

  initialErrors.splice(0, initialErrors.length);
  calendarErrors.splice(0, calendarErrors.length);
};

export const getUpdatedStateOverEditor = async ({
  state,
  context,
  options,
}: {
  state: CalendarState;
  context: CalendarContext;
  options?: {
    shouldUpdateServices?: boolean;
    shouldUpdateSlots?: boolean;
  };
}): Promise<Partial<CalendarState>> => {
  const { businessInfo, calendarApi } = context;
  const { initialErrors, calendarErrors, selectedTimezone } = state;

  clearCalendarErrors(state);

  const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(selectedTimezone!);
  const range = getLocalDateTimeRangeForDay(
    businessInfo!.regionalSettingsLocale!,
    todayLocalDateTime,
  );

  let availableServices = state.availableServices;
  if (options?.shouldUpdateServices) {
    const catalogData = await calendarApi.getCatalogData({
      onError: (type) => initialErrors.push(type),
    });
    availableServices = catalogData?.services || [];
  }

  let availableSlots = state.availableSlots;
  if (options?.shouldUpdateSlots) {
    availableSlots = await calendarApi.getSlotsInRange(range, {
      state: {
        ...state,
        availableServices,
      },
      onError: (type) => calendarErrors.push(type),
    });
  }

  return {
    availableServices,
    availableSlots,
    initialErrors,
    calendarErrors,
    slotsStatus: SlotsStatus.AVAILABLE_SLOTS,
  };
};
