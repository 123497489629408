import {
  CatalogData,
  Service,
  ServiceType,
  OfferedAsType,
  PaymentType,
  TimezoneType,
  ServiceLocationType,
} from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const DUMMY_SERVICE_IDS = ['DUMMY_ID_1', 'DUMMY_ID_2'];

export const isDummyServices = (services: Service[]) =>
  services.some((service) => DUMMY_SERVICE_IDS.includes(service.id));

const createDummyActiveFeatures = () => {
  return {
    applicableForCourse: true,
    bookingsStaffLimit: 1,
    applicableForServiceList: true,
    applicableForGroups: true,
    applicableForIndividual: true,
    applicableForPayments: true,
    applicableForReminders: true,
    applicableForSmsReminders: true,
  };
};

const createDummyService = (serviceId: string, flowAPI: ControllerFlowAPI) => {
  const { t } = flowAPI.translations;

  return {
    id: serviceId,
    info: {
      name: t('dummy-content.service.name'),
      tagline: t('dummy-content.service.tagline'),
      type: ServiceType.INDIVIDUAL,
      slug: t('dummy-content.service.name').replace(' ', '-').toLowerCase(),
      categoryId: 'some-category-id',
      categoryName: 'some-category-name',
      media: {
        gallery: [],
      },
    },
    locations: [
      {
        type: ServiceLocationType.OWNER_BUSINESS,
        businessLocation: {
          id: 'location-1-id',
        },
      },
      {
        type: ServiceLocationType.OWNER_BUSINESS,
        businessLocation: {
          id: 'location-2-id',
        },
      },
    ],
    staffMembers: [
      {
        id: 'staff-1-id',
        name: t('dummy-content.service.staff'),
      },
      {
        id: 'staff-2-id',
        name: t('dummy-content.service.staff-2'),
      },
    ],
    policy: {
      uouHidden: false,
      isBookOnlineAllowed: true,
      isPendingApprovalFlow: false,
      maxParticipantsPerBook: 1,
      capacity: 1,
      minutesBeforeSlotBookWindowStart: 0,
      minutesBeforeSlotBookWindowEnd: 0,
    },
    payment: {
      offeredAs: [OfferedAsType.ONE_TIME],
      paymentDetails: {
        currency: t('dummy-content.business.currency'),
        price: 1,
        priceText: t('dummy-content.service.price'),
        minCharge: 0,
        isFree: false,
        isVariedPricing: false,
        paymentType: PaymentType.ONLINE,
        displayTextForPlan: '',
      },
    },
  };
};

export const createDummyBusinessInfo = (flowAPI: ControllerFlowAPI) => {
  const { t, config } = flowAPI.translations;

  return {
    language: config.language,
    currency: t('dummy-content.business.currency'),
    timeZone: t('dummy-content.business.timeZone'),
    timezoneProperties: {
      defaultTimezone: TimezoneType.BUSINESS,
      clientCanChooseTimezone: true,
    },
    regionalSettingsLocale: t('dummy-content.business.regionalSettingsLocale'),
  };
};

export const createDummyCatalogData = (
  flowAPI: ControllerFlowAPI,
): CatalogData => {
  return {
    services: DUMMY_SERVICE_IDS.map((id) => createDummyService(id, flowAPI)),
    businessInfo: createDummyBusinessInfo(flowAPI),
    activeFeatures: createDummyActiveFeatures(),
  };
};
