import { Experiments } from '@wix/yoshi-flow-editor';
import { IWixAPI } from '@wix/native-components-infra/dist/es/src/types/types';
import { isAnywhereUrl } from '@wix/wix-anywhere-api';

export const isAnywhereFlow = async (
  wixCodeApi: IWixAPI,
  experiments?: Experiments,
  isPreview?: boolean,
): Promise<boolean> => {
  if (isPreview) {
    return false;
  }
  const isBookingsAnywhereUoUEnabled = experiments?.enabled(
    'specs.bookings.BookingsAnywhereUoU',
  );
  try {
    const isAnywhere = isBookingsAnywhereUoUEnabled
      ? await isAnywhereUrl({
          fullUrl: wixCodeApi.location.url,
          externalBaseUrl: wixCodeApi.location.baseUrl,
        })
      : false;
    return isAnywhere || false;
  } catch (e) {
    console.log('FAILED: isAnywhereFlow');
  }
  return false;
};
