import { PaymentServicesWeb } from '@wix/ambassador-payment-services-web/http';
import {
  PayV2PaymentMethod,
  MethodType,
  PayV2ListPaymentMethodsRequest,
} from '@wix/ambassador-payment-services-web/types';

export const paymentsBaseUrl = '/_api/payment-services-web/';
export async function hasOnlinePaymentMethods(
  req: PayV2ListPaymentMethodsRequest,
) {
  try {
    const paymentServicesWeb = PaymentServicesWeb(paymentsBaseUrl, {
      ignoredProtoHttpUrlPart: '/api',
    }).PaymentMethodService();
    const { paymentMethods } = await paymentServicesWeb().list(req);
    return paymentMethods?.some(function ({ methodType }: PayV2PaymentMethod) {
      return methodType === MethodType.ONLINE;
    });
  } catch (e) {
    console.log('hasConnectedPaymentMethods: FAILED');
  }
  return true;
}
