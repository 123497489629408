import {
  memoizedLoginViewModel,
  createLoginViewModel,
  LoginViewModel,
} from '../loginViewModel/loginViewModel';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  BookingDetailsViewModel,
  createBookingDetailsViewModel,
  memoizedBookingDetailsViewModel,
} from '../bookingDetailsViewModel/bookingDetailsViewModel';
import {
  isDailyAgendaWeeklyPickerLayout,
  isWeeklyTimetableLayout,
} from '../../../../utils/layouts';
import { Optional } from '../../../../types/types';

export type SidebarViewModel = {
  sidebarTitle: string;
  bookingDetailsViewModel: BookingDetailsViewModel;
  loginViewModel?: LoginViewModel;
};

export const memoizedSidebarViewModel: MemoizedViewModalFactory<
  Optional<SidebarViewModel>
> = {
  dependencies: {
    settings: ['bookingDetailsSectionHeader', 'calendarLayout'],
    subDependencies: [
      memoizedBookingDetailsViewModel.dependencies,
      memoizedLoginViewModel.dependencies,
    ],
  },
  createViewModel: createSidebarViewModel,
};

export function createSidebarViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<SidebarViewModel> {
  const { getContent, settings, settingsParams } = context;

  const isTimetableWidget =
    isDailyAgendaWeeklyPickerLayout(settings, settingsParams) ||
    isWeeklyTimetableLayout(settings, settingsParams);
  if (isTimetableWidget) {
    return;
  }

  return {
    sidebarTitle: getContent({
      settingsParam: settingsParams.bookingDetailsSectionHeader,
      translationKey: 'app.settings.defaults.widget.booking-details-header',
    }),
    bookingDetailsViewModel: createBookingDetailsViewModel({ state, context }),
    loginViewModel: createLoginViewModel({ state, context }),
  };
}
