import {
  PaymentType,
  Service,
  ServicePayment,
  ServiceType,
} from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { hasOnlinePaymentMethods } from '../../api/hasOnlinePaymentMethods';

export const isCalendarFlow = (service: Service) =>
  service?.info.type !== ServiceType.COURSE &&
  service?.policy.isBookOnlineAllowed;

export function isBookingsEnabled({
  serviceType,
  activeFeatures,
  isTemplateMode,
}: {
  serviceType: ServiceType;
  activeFeatures: GetActiveFeaturesResponse;
  isTemplateMode: boolean;
}): boolean {
  if (isTemplateMode) {
    return true;
  }

  switch (serviceType) {
    case ServiceType.GROUP:
      return !!activeFeatures.applicableForGroups;
    case ServiceType.INDIVIDUAL:
      return !!activeFeatures.applicableForIndividual;
    default:
      return true;
  }
}

export const isPendingApprovalFlow = (service: Service) =>
  !!service.policy?.isPendingApprovalFlow;

export const applicableForPayments = async (
  payment: ServicePayment,
  wixSdkAdapter: WixOOISDKAdapter,
) => {
  if (payment.paymentDetails.paymentType === PaymentType.ONLINE) {
    const accountId = `${wixSdkAdapter.getAppDefId()}:${wixSdkAdapter.getInstanceId()}`;
    return hasOnlinePaymentMethods({ accountId });
  }
  return true;
};
